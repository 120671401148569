import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "vue-next-select/dist/index.min.css";
import "./assets/styles/main.scss";
import VueSelect from "vue-next-select";
import Maska from "maska";

const app = createApp(App)
  .use(store)
  .use(router);
app.component("VSelect", VueSelect);
app.use(Maska);

app.mount("#app");
