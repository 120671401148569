import { createStore } from 'vuex'
import api from '../services/api'
import { debounce } from "lodash";
import { emptyForm } from './form';



export default createStore({
  state: {
    form: emptyForm,
    route: null,
    countries: {},
    businessOccupation: {},
    gettedData: null,
    loader: false,
    blur: false,
    timer: null,
    checkIdentificationTimer:null,
    timerPercent: 0,
    completed: 0,
    phoneCodes: {},
    allowedCountries: {},
    footerNotes: null,
    managerAuth: true,
    isDataCheck: false,
    dataCheckErrors: null,
    formChangedData: [],
    reviewTimer:null,
  },
  mutations: {
    setForm(state, data) {
      if (Object.keys(data).length) {
        if (data.investmentProfile !== undefined) {
          state.form = data
          state.completed = data.completed
        } else if (data.prefilledData !== undefined) {
          state.form.prefilledData = data.prefilledData
          state.completed = data.completed
        }
      }
      state.gettedData = data
    },
    setFormValue(state, data) {
      state.form[data.key] = data.value
    },
    setCountries(state, data) {
      state.countries = data
    },
    setBusinessOccupation(state, data) {
      state.businessOccupation = data
    },
    setLoader(state, value) {
      state.loader = value
    },
    setBlur(state, value) {
      state.blur = value
    },
    SET_ROUTE(state, route) {
      state.route = route;
    }
  },
  actions: {
    async loginTsSend(ctx) {
      ctx.state.form.loginTs = new Date().getTime();
      ctx.dispatch('storeForm')
    },
    async signTsSend(ctx) {
      ctx.state.form.signTs = new Date().getTime();
      ctx.dispatch('storeForm')
    },
    async fetchForm(ctx) {
      const res = await api.get("/onboarding/" + ctx.state.route.params.id)
      ctx.commit('setForm', res.data.data)
    },
    async fetchFooterNotes(ctx) {
      const res = await api.get("/footnotes.json")
      ctx.state.footerNotes = res.data;
    },
    async fetchCountries(ctx) {
      const res = await api.get("/country_state.json")
      ctx.commit('setCountries', res.data)
    },
    async fetchAllowedCountries(ctx) {
      const res = await api.get("/allowed_country_state.json")
      ctx.state.allowedCountries = res.data
    },
    async fetchPhoneCodes(ctx) {
      const res = await api.get("/phone_codes.json")
      ctx.state.phoneCodes = res.data
    },
    async fetchBusinessOccupation(ctx) {
      const res = await api.get("/employer_business_occupation.json")
      ctx.commit('setBusinessOccupation', res.data)
    },
    async storeForm(ctx) {
      await api.post('/onboarding/' + ctx.state.route.params.id, JSON.stringify({
        ...ctx.state.form,
        ...{
          debug: {
            ref: window.location.href,
            changed: ctx.state.formChangedData
          }
        }
      }))
      ctx.state.formChangedData = [];
    },
    setValue: debounce(function ({ commit, dispatch }, data) {
      commit("setFormValue", data)
      dispatch("storeForm")
    }, 300),
    turnOnLoader: ({ commit }) => {
      commit('setLoader', true)
      commit('setBlur', true)
    },
    turnOffLoader: ({ commit }) => {
      commit('setLoader', false)
      commit('setBlur', false)
    },
    turnOnBlur: ({ commit }) => {
      commit('setBlur', true)
    },
    turnOffBlur: ({ commit }) => {
      commit('setBlur', false)
    },
  },
  getters: {
    onbForm: state => {
      return state.form
    },
    gettedData: state => {
      return state.gettedData
    },
    countryList: state => {
      return state.countries
    },
    businessOccupationList: state => {
      return state.businessOccupation
    },
    footerNotes: state => {
      return state.footerNotes ?? {}
    },
    allowedCountries: state => {
      return state.allowedCountries ?? []
    }
  }
})
