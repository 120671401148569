import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import api from "../services/api";
import moment from 'moment';

const routes = [

  {
    path: '/investment-profile/:id?',
    name: 'InvestmentProfile',
    meta: { title: 'Investment Profile - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "InvestmentProfile" */ '../views/InvestmentProfile.vue')
  },
  {
    path: '/online-identification/:id?',
    name: 'OnlineIdentification',
    meta: { title: 'Online Identification - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "OnlineIdentification" */ '../views/OnlineIdentification.vue')
  },
  {
    path: '/your-investment-profile/:id?',
    name: 'YourInvestmentProfile',
    meta: { title: 'Your Investment Profile - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "YourInvestmentProfile" */ '../views/YourInvestmentProfile.vue')
  },
  {
    path: '/your-personal-details/:id?',
    name: 'YourPersonalDetails',
    meta: { title: 'Your Personal Details - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "YourPersonalDetails" */ '../views/YourPersonalDetails.vue')
  },
  {
    path: '/about-you/:id?',
    name: 'AboutYou',
    meta: { title: 'About You - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "AboutYou" */ '../views/AboutYou.vue')
  },
  {
    path: '/review-and-sign/:id?',
    name: 'ReviewAndSign',
    meta: { title: 'Review And Sign - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "ReviewAndSign" */ '../views/ReviewAndSign.vue')
  },
  {
    path: '/life-situation/:id?',
    name: 'LifeSituation',
    meta: { title: 'Your Life Situation - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "LifeSituation" */ '../views/LifeSituation.vue')
  },
  {
    path: '/thank-you/:id?',
    name: 'ThankYou',
    meta: { title: 'Thank you! - Simplewealth onboarding' },
    component: () => import(/* webpackChunkName: "ThankYou" */ '../views/ThankYou.vue')
  },
  {
    path: '/management/:id?',
    name: 'Management',
    meta: { title: 'Management - Simplewealth onboarding', canSkipId: true },
    component: () => import(/* webpackChunkName: "Management" */ '../views/Management.vue')
  },
  {
    path: '/:id?',
    name: 'Home',
    meta: { title: 'Overview - Simplewealth onboarding' },
    component: Home,
  },
]

const scrollBehavior = (to) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (to.hash) {
        resolve({
          selector: to.hash,
          behavior: 'smooth',
        })
      } else {
        resolve({ left: 0, top: 0 })
      }
    }, 1)
  })
}

const router = createRouter({
  scrollBehavior,
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// router.beforeEach(async (to, from, next) => {
//   store.commit('SET_ROUTE', to);
//
//
//   // if ID is saved in localStorage already and it's != to.params.id
//   // than ignore to.params.id and use saved
//   if (![null, undefined, "", to.params.id].includes(localStorage.getItem('id'))) {
//
//     to.params.id = localStorage.getItem('id');
//     next(to)
//   }
//
//   if (to.params.id !== undefined && to.params.id != "" && ![null, undefined, ""].includes(localStorage.getItem('id'))) {
//     to.params.id = localStorage.getItem('id');
//     await store.dispatch('fetchForm')
//     next(to)
//   } else {
//     // if no ID provided - redirect to some random ID and store ID to localStorage
//     to.name = 'Home';
//     if ([null, undefined, ""].includes(localStorage.getItem('id'))) {
//       to.params.id = ((Date.now()).toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
//       localStorage.setItem('id', to.params.id)
//     } else {
//       to.params.id = localStorage.getItem('id');
//     }
//
//     next(to)
//   }
// })

router.beforeEach(async (to, from, next) => {
  store.commit('SET_ROUTE', to);

  if (!store.state.footerNotes) {
    await store.dispatch('fetchFooterNotes')
  }

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  api.post('/log', JSON.stringify({
    msg: {
      date: new Date(Date.now()).toLocaleString('ch-CH', { timeZone: 'Europe/Zurich' }),
      url: to.fullPath,
      ls: localStorage.getItem('id'),
    }
  }))



  if (![null, undefined, ""].includes(localStorage.getItem('id'))) {
    // everything fine - GET == ls, so just proceed
    if (to.params.id !== undefined && to.params.id === localStorage.getItem('id')) {
      // console.log({m: 'LS == GET', ls: localStorage.getItem('id'), get: to.params.id})
      // api.post('/log', JSON.stringify({m: 'LS == GET', ls: localStorage.getItem('id'), get: to.params.id}))
      await store.dispatch('fetchForm')
      next()
    } else { // something unusual here
      if (to.query.force !== undefined) {   // there is ID inside ls && force - change ID inside ls
        console.log({m: 'LS && FORCE', ls: localStorage.getItem('id'), get: to.params.id})
        api.post('/log', JSON.stringify({m: 'LS && FORCE', ls: localStorage.getItem('id'), get: to.params.id}))
        localStorage.setItem('id', to.params.id)
        await store.dispatch('fetchForm')
        next()
      } else { // there is ID inside ls && no force - let's use ID from ls instead of GET params
        console.log({m: 'LS noFORCE', ls: localStorage.getItem('id'), get: to.params.id})
        api.post('/log', JSON.stringify({m: 'LS noFORCE', ls: localStorage.getItem('id'), get: to.params.id}))
        to.params.id = localStorage.getItem('id')
        next(to)
      }
    }
  } else { // no ID inside ls
    if (to.params.id !== undefined && to.params.id != "") {  // no ID inside ls but there is GET - use GET
      console.log({m: 'noLS && ID', ls: localStorage.getItem('id'), get: to.params.id})
      api.post('/log', JSON.stringify({m: 'noLS && ID', ls: localStorage.getItem('id'), get: to.params.id}))
      localStorage.setItem('id', to.params.id)
      await store.dispatch('fetchForm')
      next()
    } else {  // no ID inside ls and no GET - generate new ID, redirect to /
      console.log({m: 'noLS no ID', ls: localStorage.getItem('id'), get: to.params.id})
      api.post('/log', JSON.stringify({m: 'noLS no ID', ls: localStorage.getItem('id'), get: to.params.id}))
      to.name = 'Home';
      to.params.id = ((Date.now()).toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
      if(process.env.NODE_ENV == 'e2e'){
        to.params.id = "TEST"+(moment(Date.now()).format('YYMMDDhhmmss'));
      }
      localStorage.setItem('id', to.params.id)
      next(to)
    }
  }


  // if (to.params.id !== undefined && to.params.id != "" && ![null, undefined, ""].includes(localStorage.getItem('id'))) {
  //   // in case we need to replace user's id
  //   // we can call https://.../ID?refresh
  //   if (to.query.force !== undefined) {
  //     console.log("force refreshing localStorage...");
  //     api.post('/log', JSON.stringify({
  //       msg: {
  //         date: new Date(Date.now()).toLocaleString('ch-CH', { timeZone: 'Europe/Zurich' }),
  //         msg: "force refreshing localStorage...",
  //         old: localStorage.getItem('id'),
  //         new: to.params.id
  //       }
  //     }))
  //     localStorage.setItem('id', to.params.id)
  //   }
  //   await store.dispatch('fetchForm')
  //   next()
  // } else {
  //   // if no ID provided - redirect to some random ID and store ID to localStorage
  //   if (to.meta.canSkipId) {
  //     next()
  //   } else {
  //     to.name = 'Home';
  //     if ([null, undefined, ""].includes(localStorage.getItem('id'))) {
  //       to.params.id = ((Date.now()).toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
  //       localStorage.setItem('id', to.params.id)
  //     } else {
  //       to.params.id = localStorage.getItem('id');
  //     }
  //   }
  //   next(to)
  // }
})

export default router
