<template>
  <MainLayout mini>
    <div class="lgs-page-title mb-4">Let’s get started!</div>
    <div>
      We are trying to make it easy and quick to start working together.
      However, we need to comply with regulatory requirements regarding
      documentation and identification.
    </div>
    <div class="mb-4 mt-5 lgs-page-subtitle">The whole process is divided into several steps.</div>

    <div class="mb-4">
      You will need your passport, tax number (SSN or TIN, or both if you have
      more than one nationality) and any written proof of address (electricity,
      water, gas bill).
    </div>
    <div class="mb-4">
      Once you submit your details and proceed with the online identification,
      we will prepare a contract, and you can sign it online. The originals will
      be sent to you by post.
    </div>
    <div class="mb-4 small">
      Thank you for being with us,
      <br />Simplewealth
    </div>

    <router-link
      :to="{ name: 'InvestmentProfile', params: { id: $route.params.id } }"
      class="btn btn-primary px-4 button-padding"
      id="cyHomeGo"
    >Continue to account setup&nbsp;&nbsp; &rarr;</router-link>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";


export default {
  components: {
    MainLayout,
  },
  name: "Home",
 
};
</script>
