<template>
  <div class="SwProgress">
    <div
      :style="{'width':percent+'%'}"
      class="SwProgress_bar"
    />
  </div>
</template>

<script>

export default {
  name: "SwProgress",
  data: () => {
    return {};
  },

  props: ["percent"],
};
</script>