export const emptyForm = {
    prefilledData: {
        fname: null,
        lname: null,
        email: null,
        deposit: 5000,
        monthly: 250,
        period: 10
    },
    investmentProfile: {
        filledQuestions: 1,
        whyAreYouInvesting: [],
        howYouWantToSpendAdditionalIncome: [],
        investmentHorizon: null,
        age: 18,
        dependents: 10,
        howDoYouSpendYourDays: null,
        expectedIncome: 50000,
        monthlySavings: 250,
        assets: 10000,
        savings: 10000,
        debts: 0,
        investedBefore: null,
        riskAppetite1: null,
        riskAppetite2: null,
        annualNetIncome: null,
        illiquidNetWorth: null,
        liquidNetWorth: null
    },
    lifeSituation: {
        growth: true,
        hedging: false,
        "preservation/income": false,
        speculation: true,
        completed: 0,
        maritalStatus: "married",
        dependents: 0,
        employment: null,
        employmentDetails: {
            employer: "",
            occupation: null,
            employer_business: null,
            employer_address: {
                addr: "",
                city: null,
                state: null,
                country: null,
                postal_code: null
            }
        },
        additionalIncome: false,
        additionalIncomeDetails: {
            employment: 100,
            allowance: 0,
            disability: 0,
            inheritance: 0,
            interest: 0,
            market: 0,
            pension: 0,
            property: 0,
            other: 0,
            reason: ""
        },
        regulatoryAffiliation: false,
        regulatoryAffiliationDetails: [
            {
                relation: null,
                name: "",
                company: "",
                addr: null,
                city: null,
                state: null,
                country: null,
                postal_code: null,
                phone: null,
                email: null
            },
        ],
        regulatoryOfficer: false,
        regulatoryOfficerSymbols: "",
        neverInvestBefore: false,
        investmentExperienceDetails: [
            {
                years_trading: "0 Years of Experience",
                trades_per_year: "0 Trades per Year",
                knowledge_level: "No Knowledge",
                asset_class: "Stocks"
            },
            {
                years_trading: "0 Years of Experience",
                trades_per_year: "0 Trades per Year",
                knowledge_level: "No Knowledge",
                asset_class: "Bonds"
            },
            {
                years_trading: "0 Years of Experience",
                trades_per_year: "0 Trades per Year",
                knowledge_level: "No Knowledge",
                asset_class: "Options"
            },
            {
                years_trading: "0 Years of Experience",
                trades_per_year: "0 Trades per Year",
                knowledge_level: "No Knowledge",
                asset_class: "Futures"
            },
            {
                years_trading: "0 Years of Experience",
                trades_per_year: "0 Trades per Year",
                knowledge_level: "No Knowledge",
                asset_class: "Currency"
            }
        ]
    },
    aboutYou: {
        gender: "M",
        dob: null,
        first: "",
        last: "",
        residenceAddr: {
            addr: null,
            city: null,
            state: null,
            country: null,
            postalCode: "",
            phoneCountry: null,
            phone: null
        },
        passport_number: "",
        tax_number: "",
        IdExpirationDate:null,
        differentMailingAddr: false,
        mailing_addr: {
            addr: null,
            city: null,
            state: null,
            country: null,
            postal_code: null
        },
        differentTaxResidency: false,
        dontHaveTax: false,
        dontHaveTaxDetails: {
            option: null,
            reason: ""
        },
        differentTaxResidencyDetails: {
            country: null,
            tax_number: null,
        },
        citizenship: null,
        differentCitizenship: false,
        differentCitizenshipDetails: {
            citizenship: null
        },
        placeOfBirth: null,
        differentPlaceOfBirth: false,
        differentPlaceOfBirthDetails: {
            placeOfBirth: null
        },
        usCitizen: false
    },
    onlineIdentification: {
        identityAccessKey: null,
        identityAccessKeyTimestamp: null,
        documentType: null
    }
};