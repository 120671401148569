<template>
  <div class="position-relative mt-4 mh-130">
    <div class="position-absolute">
      <div class="account-progress">
        <div class="ap-percent">
          {{ percent }}
        </div>
        <div class="ap-text">
          profile completed
        </div>
      </div>
      <circle-progress
        :percent="percent"
        :is-gradient="true"
        empty-color="#DDDFF0"
        :gradient="{
          angle: 90,
          startColor: '#DDDFF0',
          stopColor: '#FC006A',
        }"
        :size="130"
        :border-width="10"
        :border-bg-width="10"
      />
    </div>
  </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
  components: {
    CircleProgress,
  },
  name: "SwProgressCircle",
  data: () => {
    return {};
  },

  props: ["percent"],
};
</script>