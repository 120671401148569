<template>
  <div>
    <div
      class="loader-cont"
      v-if="$store.state.loader"
      :key="'appLoader'"
    >
      <img
        src="@/assets/loader.svg"
        alt
      >
    </div>
    <div
      class="timer"
      v-if="$store.state.timer"
    >
      <div class="d-flex flex-column align-items-center">
        <circle-progress
          :percent="$store.state.timerPercent"
          empty-color="#FFFFFF"
          fill-color="#FC006A"
          :size="150"
          :border-width="12"
          :border-bg-width="14"
        />
        <div class="timerText mt-3">
          {{ $store.state.timerText }}
        </div>
      </div>
    </div>

    <div
      class="loginFormWrapper"
      v-if="$store.state.isDataCheck"
    >
      <div class="loginForm p-4 col-11 col-md-8 col-lg-6">
        <div
          id="mlSending"
          class="text-center"
          v-if="!$store.state.dataCheckErrors"
        >
          Sending your application to the broker...
          <div class="d-flex flex-column align-items-center mt-4">
            <circle-progress
              :percent="$store.state.timerPercent"
              empty-color="#f7f7f7"
              fill-color="#FC006A"
              :size="150"
              :border-width="12"
              :border-bg-width="14"
            />
            <div class="timerText mt-3">
              {{ $store.state.timerText }}
            </div>
          </div>
        </div>
        <div v-if="$store.state.dataCheckErrors">
          <div class="errorPopupTitle">
            Broker has rejected your application with following messages
          </div>

          <ul class="my-4">
            <li
              v-for="error, i in $store.state.dataCheckErrors"
              :key="'data-error' + i"
            >
              {{ error }}
            </li>
          </ul>
          <div
            class="btn btn-primary px-4 button-padding"
            @click="editApp"
          >
            Edit application
          </div>
        </div>
      </div>
    </div>

    <div
      class="loginFormWrapper"
      v-if="!$store.state.managerAuth"
    >
      <div class="loginForm col-11 col-md-6 col-lg-4">
        <div class="sw-label">
          <div class="sw-label-text">
            Login
          </div>
          <div class="d-flex swl-content col-12">
            <div class="sw-textfield w-100">
              <input
                type="text"
                placeholder="Enter login"
                v-model="loginData.l"
              >
            </div>
          </div>
        </div>
        <div class="sw-label mt-3">
          <div class="sw-label-text">
            Password
          </div>
          <div class="d-flex swl-content col-12">
            <div class="sw-textfield w-100">
              <input
                type="password"
                placeholder="Enter password"
                v-model="loginData.p"
              >
            </div>
          </div>
        </div>
        <div
          class="errorMessage my-3"
          v-if="loginMessage"
        >
          {{ loginMessage }}
        </div>
        <div
          class="btn btn-primary mt-2 px-4 button-padding w-100"
          @click="signIn"
        >
          Sign In
        </div>
      </div>
    </div>

    <div :class="{ loader: $store.state.blur }">
      <section>
        <div class="container-fluid">
          <div class="row px-md-3 px-xl-6">
            <div class="d-flex my-3">
              <div class="col-3 d-flex d-md-none">
                <div
                  class="icon_btn"
                  :class="{ opened: opened }"
                  @click="opened = !opened"
                >
                  <img
                    src="@/assets/icons/icn_menu_closed.svg"
                    class="icon_btn_closed"
                  >
                  <img
                    src="@/assets/icons/icn_close.svg"
                    class="icon_btn_close"
                  >
                </div>
              </div>
              <div
                class="col-9 col-md-3 justify-content-end justify-content-md-start d-flex align-items-center"
              >
                <img
                  src="@/assets/logo.svg"
                  class="logo-image"
                  alt
                >
              </div>
              <div class="col-9 justify-content-end d-none d-md-flex">
                <!--div class="btn">
                  My account
                </div>
                <div class="btn btn-primary ms-2">
                  Logout
                </div-->
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <collapse-transition dimension="height">
          <div
            class="bg-grey"
            v-if="opened"
          >
            <div class="d-flex py-5 px-3 flex-column" id="mobileMenu">
              <router-link
                :to="{ name: 'Home', params: { id: $route.params.id } }"
                class="mob-menu-item"
              >
                Overview
              </router-link>
              <router-link
                :to="{
                  name: 'InvestmentProfile',
                  params: { id: $route.params.id },
                }"
                class="mob-menu-item"
              >
                Your investment profile
              </router-link>
              <router-link
                :to="{
                  name: 'OnlineIdentification',
                  params: { id: $route.params.id },
                }"
                :class="{ disabled: $store.state.completed < 40 }"
                class="mob-menu-item"
              >
                Online identification
              </router-link>
              <router-link
                :to="{
                  name: 'LifeSituation',
                  params: { id: $route.params.id },
                }"
                :class="{ disabled: $store.state.completed < 40 }"
                class="mob-menu-item"
              >
                Your life situation
              </router-link>
              <router-link
                :to="{ name: 'AboutYou', params: { id: $route.params.id } }"
                :class="{ disabled: $store.state.completed < 78 }"
                class="mob-menu-item"
              >
                Your personal details
              </router-link>
              <router-link
                :to="{
                  name: 'ReviewAndSign',
                  params: { id: $route.params.id },
                }"
                :class="{ disabled: $store.state.completed < 98 }"
                class="mob-menu-item"
              >
                Review and sign
              </router-link>
            </div>
          </div>
        </collapse-transition>
      </section>

      <section class="content-bg">
        <SwProgress
          :percent="$store.state.completed"
          class="d-none d-md-block d-xl-none"
        />

        <div class="container">
          <div class="row py-5 pt-md-3 my-xl-5">
            <div class="col-xl-4 col-12 d-flex flex-column" id="desktopMenu">
              <div
                class="flex-xl-column main-menu justify-content-between justify-content-xl-start d-none d-md-flex mb-3 mb-xl-none"
              >
                <router-link :to="{ name: 'Home', params: { id: $route.params.id } }">
                  Overview
                </router-link>
                <router-link
                  :to="{
                    name: 'InvestmentProfile',
                    params: { id: $route.params.id },
                  }"
                >
                  Your investment profile
                </router-link>
                <router-link
                  :to="{
                    name: 'OnlineIdentification',
                    params: { id: $route.params.id },
                  }"
                  :class="{ disabled: $store.state.completed < 40 }"
                >
                  Online identification
                </router-link>
                <router-link
                  :to="{
                    name: 'LifeSituation',
                    params: { id: $route.params.id },
                  }"
                  :class="{ disabled: $store.state.completed < 64 }"
                >
                  Your life situation
                </router-link>
                <router-link
                  :to="{ name: 'AboutYou', params: { id: $route.params.id } }"
                  :class="{ disabled: $store.state.completed < 78 }"
                >
                  Your personal details
                </router-link>
                <router-link
                  :to="{
                    name: 'ReviewAndSign',
                    params: { id: $route.params.id },
                  }"
                  :class="{ disabled: $store.state.completed < 98 }"
                >
                  Review and sign
                </router-link>
              </div>

              <SwProgressCircle
                :percent="$store.state.completed"
                class="d-none d-xl-block"
              />
            </div>
            <div
              class="col-xl-8 col-12"
              v-if="!mini"
            >
              <slot />
            </div>
            <div
              class="col-xl-6 col-12"
              v-if="mini"
            >
              <slot />
            </div>
          </div>
        </div>
      </section>
      <!-- footer -->
      <section class="footer-bg">
        <div class="container py-5">
          <div class="row d-md-none mb-5">
            <!--div-- class="d-flex flex-column mt-3">
              <a
                href="#"
                class="btn btn-secondary px-4 py-2"
              >Sign In</a>
              <a
                href="#"
                class="btn btn-primary px-4 py-2 mt-2"
              >Sign Up</a>
            </div-->
          </div>
          <div class="row d-flex flex-column flex-md-row">
            <div class="col-xl-4 col-3 mb-5 mb-md-0">
              <img
                src="@/assets/logo.svg"
                height="30"
                alt
              >
            </div>
            <div class="row col-xl-8 col-9 d-flex flex-md-row-reverse flex-xl-row">
              <div class="row d-flex flex-column flex-md-row col-12 col-md-8">
                <div class="col-12 col-md-4 mt-4 mt-md-0">
                  <span class="cl-black">Navigation</span>
                  <div class="d-flex flex-column mt-3 sw-links">
                    <a href="https://www.simplewealth.ch/how-to/">How to start</a>
                    <a href="https://www.simplewealth.ch/fees/">Our plans & pricing</a>
                    <a href="https://www.simplewealth.ch/press/">Media & Press</a>
                    <a href="https://www.simplewealth.ch/about-us/">About Us</a>
                    <a href="https://www.simplewealth.ch/insights/">Insights</a>
                    <a href="https://www.simplewealth.ch/faq/">FAQ</a>
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-4 mt-md-0">
                  <span class="cl-black">Social</span>
                  <div class="d-flex flex-column mt-3 sw-links">
                    <a href="https://www.facebook.com/Simplewealth">Facebook</a>
                    <a href="https://twitter.com/SimpleWealth">Twitter</a>
                    <a href="https://www.linkedin.com/company/simplewealth-ag">Linkedin</a>
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-4 mt-md-0">
                  <span class="cl-black">Documents</span>
                  <div class="d-flex flex-column mt-3 sw-links">
                    <a href="https://www.simplewealth.ch/policy/">Privacy Policy</a>
                    <a href="https://www.simplewealth.ch/terms/">Terms of use</a>
                    <a href="https://www.simplewealth.ch/impressum/">Imprint</a>
                  </div>
                </div>
              </div>

              <div class="col-4 d-flex justify-content-xl-end d-none d-md-flex">
                <!--div-- class="col-xl-6 col-12">
                  <span>Autorisation</span>
                  <div class="d-flex flex-column mt-3">
                    <a
                      href="#"
                      class="btn btn-secondary px-4 py-2"
                    >Sign In</a>
                    <a
                      href="#"
                      class="btn btn-primary px-4 py-2 mt-2"
                    >Sign Up</a>
                  </div>
                </div-->
              </div>
            </div>
          </div>

          <div class="row mt-5 pt-3">
            <div class="col-12 col-xl-4">
              <div class="col-12 d-xl-none d-flex flex-column flex-md-row">
                <a
                  href="#"
                  class="btn btn-outline-dark me-md-4 mb-2 mb-md-0 col-12 w-auto"
                >
                  <img
                    src="@/assets/appstore-black.png"
                    alt
                  >
                </a>
                <a
                  href="#"
                  class="btn btn-outline-dark me-md-4 mb-2 mb-md-0 col-12 w-auto"
                >
                  <img
                    src="@/assets/playmarket-black.png"
                    alt
                  >
                </a>
              </div>
              <div class="col-12 d-none d-xl-block">
                <a
                  href="#"
                  class="btn btn-primary px-4 py-3"
                >
                  <img
                    src="@/assets/appstore.svg"
                    alt
                  >
                </a>
                <a
                  href="#"
                  class="btn btn-primary px-4 py-3 ms-4"
                >
                  <img
                    src="@/assets/playmarket.svg"
                    alt
                  >
                </a>
              </div>
              <div class="col-12 mt-4 sw-copyright">
                © Simplewealth AG 2015-2021. Made
                <br class="d-md-none">with ❤️ in beautiful 🇨🇭
              </div>
            </div>
            <div class="col-12 col-xl-8 mt-4 mt-lg-auto">
              <div class="d-flex mb-5 justify-content-xl-between flex-wrap">
                <div class="footer-brand my-3 my-xl-0 col-12 col-md-4">
                  <div class="w-100">
                    <img
                      src="@/assets/footer-brand-1.png"
                      class="mw-100 h-100"
                    >
                  </div>
                </div>
                <div class="footer-brand my-3 my-xl-0 col-12 col-md-4 px-md-3">
                  <div class="w-100">
                    <img
                      src="@/assets/footer-brand-2.png"
                      class="mw-100 h-100"
                    >
                  </div>
                </div>
                <div class="footer-brand my-3 my-xl-0 col-12 col-md-4">
                  <div class="w-100">
                    <img
                      src="@/assets/footer-brand-3.png"
                      class="mw-100 h-100"
                    >
                  </div>
                </div>
              </div>
              <div class="row row-cols-md-2">
                <span class="footer-small-text mt-md-0">
                  Investing in securities involves risks, and there is always
                  the potential of losing money when you invest in securities.
                  Before investing, consider your investment objectives and
                  Simplewealth AG charges and expenses.
                </span>
                <span class="footer-small-text mt-4 mt-md-0">
                  Past performance is not an indicator of future performance.
                  Unless otherwise specified, all return figures shown above are
                  for illustrative purposes only and are not actual customer or
                  model returns. Actual returns will vary greatly and depend on
                  personal and market conditions.
                </span>
                <span class="footer-small-text mt-4">
                  Simplewealth AG is a Swiss-based advisory service, designed to
                  assist clients in achieving discrete financial goals. We are
                  not intended to provide comprehensive tax advice or financial
                  planning with respect to every aspect of a client's financial
                  situation and do not incorporate specific investments that
                  clients hold elsewhere.
                </span>
                <span class="footer-small-text mt-4">
                  Investing implies your capital is at risk. The value of your
                  account depends on market movements and you may get back less
                  than you invest.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="bg-black"
        v-if="Object.keys(footerNotes).includes($route.name)"
      >
        <div class="container py-5">
          <div class="row py-5">
            <div class="col-12 d-flex flex-column">
              <span class="footer-text-title">
                <div class="before-small-text">1</div>
                {{ footerNotes[$route.name][0].title }}
              </span>
              <span class="footer-text mt-3">{{ footerNotes[$route.name][0].body }}</span>
              <span class="footer-text-title mt-4">
                <div class="before-small-text">2</div>
                {{ footerNotes[$route.name][1].title }}
              </span>
              <span class="footer-text mt-3">{{ footerNotes[$route.name][1].body }}</span>
            </div>
          </div>
        </div>
      </section>
      <!-- /footer -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.errorPopupTitle {
  font-size: 20px;
  line-height: 125%;
}
.loginFormWrapper {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  .loginForm {
    background: white;
    padding: 45px;
    box-shadow: 0px 2px 5px 0px #dddddd;
    border-radius: 10px;
  }
  .errorMessage {
    color: #ff3636;
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: #dddff0;
}
.loader {
  filter: blur(8px);
}
.loader-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  user-select: none;
}
.mh-130 {
  min-height: 130px;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  user-select: none;

  .timerText {
    font-family: "SuisseIntl-Medium";
    font-weight: 500;
    color: #090d2e;
    user-select: none;
  }
}
</style>

<script>
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import SwProgressCircle from "@/components/SwProgressCircle.vue";
import SwProgress from "@/components/SwProgress.vue";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { mapGetters } from "vuex";
import api from "@/services/api";
import Cookies from "js-cookie";

export default {
  components: {
    CollapseTransition,
    SwProgressCircle,
    SwProgress,
    CircleProgress,
  },
  name: "MainLayout",
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["footerNotes"]),
  },

  methods: {
    async signIn() {
      const res = await api.post("auth", JSON.stringify(this.loginData));
      if (res.data.success) {
        this.$store.state.managerAuth = true;
        this.$store.state.blur = false;
        this.loginMessage = null;
        Cookies.set("auth", "true", {
          expires: 1,
        });
      } else {
        this.loginMessage = "Incorrect login or password! Try again!";
      }
    },
    editApp() {
      this.$store.state.isDataCheck = false;
      this.$store.state.blur = false;
      this.$store.state.dataCheckErrors = null
    }
  },
  data: () => {
    return {
      opened: false,
      loginData: {
        l: null,
        p: null,
      },
      loginMessage: null,
    };
  },
};
</script>
